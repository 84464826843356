@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --white: #ffffff;
  --blue: #015dfe;
  --blue-calm: #e6f6ff;
  --blue-dark: #014acb;
  --grey-dark: #838e9f;
  --neutral-black: #1c1260;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import '@aws-amplify/ui-react/styles.css';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@font-face {
  font-family: Alphasans;
  src: url(../public/fonts/AlphasansRegular.woff);
}

@font-face {
  font-family: Alphasans-Bold;
  src: url(../public/fonts/AlphasansBold.woff);
}

@font-face {
  font-family: Alphasans-Light;
  src: url(../public/fonts/AlphasansLight.woff);
}

@font-face {
  font-family: Alphasans-Medium;
  src: url(../public/fonts/AlphasansMedium.woff);
}

@font-face {
  font-family: Alphasans-ExtraBold;
  src: url(../public/fonts/AlphasansExtraBold.woff);
}

[class*='amplify'] {
  font-family: Alphasans, Alphasans-Bold, Alphasans-Light, Alphasans-Medium, Alphasans-ExtraBold !important;
}

:root {
  --amplify-fonts-default-variable: Alphasans, Alphasans-Bold, Alphasans-Light, Alphasans-Medium, Alphasans-ExtraBold;
  --amplify-fonts-default-static: Alphasans, Alphasans-Bold, Alphasans-Light, Alphasans-Medium, Alphasans-ExtraBold;
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);
  --amplify-components-badge-success-background-color: #30d158;
  --amplify-components-badge-success-color: #ffffff;
  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
}

html,
body {
  font-family: Alphasans, Alphasans-Bold, Alphasans-Light, Alphasans-Medium, Alphasans-ExtraBold !important;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
}

.icon-20 {
  width: 20px;
  height: 20px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.use-alphalitical-svg {
  width: 929px;
  height: 901px;
}

.use-alphalitical-icons {
  width: 100px;
  height: 100px;
}

.why-alphalitical-svg {
  width: 634px;
  height: 660px;
}

.graph-svg {
  width: 1092px;
  height: 377px;
}

.maps-svg {
  width: 960px;
  height: 464px;
}
